export default [
    // 登录页面
    {
        path: '/singleSignOn',
        name: 'singleSignOn',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/singleSignOn")
    },

    // 未登录
    {
        path: '/error',
        name: 'error',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/error")
    },
    {
        path: '/',
        name: 'home',
        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/home"),
        children: [
            // 运营管理 resourceManager
            {
                path: '/operationManagement',
                name: 'operationManagement',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/operationManagement")
            },
            // 配置管理 configurationManagement
            {
                path: '/configurationManagement',
                name: 'configurationManagement',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/configurationManagement")
            },
            // 聊天记录一级 records
            {
                path: '/records',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/records"),
                children:[
                    // 聊天记录列表 chatRecords
                    {
                        path: '',
                        name: 'chatRecords',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/chatRecords"),
                        meta: {
                            keepAlive: true // 需要缓存
                        }
                    },
                    // 聊天记录-群聊详情 groupChatDetail
                    {
                        path: 'groupChatDetail',
                        name: 'groupChatDetail',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/groupChatDetail")
                    },
                    // 聊天记录-聊天记录 chatRecordDetail
                    {
                        path: 'chatRecordDetail',
                        name: 'chatRecordDetail',
                        component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/chatRecordDetail")
                    },
                ]
            },
            // 任务进度 taskProgress
            {
                path: '/taskProgress',
                name: 'taskProgress',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/taskProgress")
            },
            // 数据日志 dataLog
            {
                path: '/dataLog',
                name: 'dataLog',
                component:  ()=>import(/* webpackChunkName: "group-foo" */"@/views/dataLog")
            },
        ]
    },
]
